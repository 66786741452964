import Swiper from 'swiper/bundle';

class Carousel {
  constructor({ app, elm, nextElm, prevElm }) {
    this.app = app;
    this.elm = elm;
    this.nextElm = nextElm;
    this.prevElm = prevElm;
    this.swiper = new Swiper(this.elm, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      navigation: {
        nextEl: this.nextElm,
        prevEl: this.prevElm,
      },
    });

    this.nextElm.addEventListener('click', () => {
      this.handleNavClick();
    });
    this.prevElm.addEventListener('click', () => {
      this.handleNavClick();
    });
  }

  handleNavClick() {
    const newAreaId = this.swiper.slides[this.swiper.activeIndex].dataset.areaId;
    this.app.state.areaId = newAreaId;
    this.app.render();
  }

  render() {
    Array.from(this.swiper.slides).forEach((slide, index) => {
      const isActiveSlide = slide.dataset.areaId === this.app.state.areaId;
      if (isActiveSlide) {
        this.swiper.slideTo(index);
        slide.classList.add(this.app.activeClassName);
      }
      if (!isActiveSlide) {
        slide.classList.remove(this.app.activeClassName);
      }
    });
  }
}

export default Carousel;
