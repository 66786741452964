class ImagemapArea {
  constructor({ elm, app, scrollLocationTarget, scrollLocationTargetOffset }) {
    this.elm = elm;
    this.app = app;
    this.scrollLocationTarget = scrollLocationTarget;
    this.scrollLocationTargetOffset = scrollLocationTargetOffset;
    this.id = this.elm.dataset.areaId;

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();
      this.app.state.areaId = this.id;
      this.app.render();

      if (this.app.state.isSpLayout) {
        const { x, y } = this.scrollLocationTarget.getBoundingClientRect();
        scrollBy(x, y + this.scrollLocationTargetOffset);
      }
      return false;
    });
  }

  render() {
    const isActiveArea = this.app.state.areaId === this.id;
    if (isActiveArea) this.elm.classList.add(this.app.activeClassName);
    if (!isActiveArea) this.elm.classList.remove(this.app.activeClassName);
  }
}

export default ImagemapArea;
