// TopAreaMapApp
//
// トップページにあるエリアイラスト & カードカルーセルのパーツ

import Carousel from './_Carousel';
import ImagemapArea from './_ImagemapArea';
import PartialImage from './_PartialImage';
import ImagemapResizer from './_ImagemapResizer';

export default class TopAreaMapApp {
  defaultProps = {
    appElm: document.querySelector('[data-role="top-areamap-app"]'),
    areaId: null,
    isSpLayout: null,
    bp: 576, // Default Bootstrap Small Size
    activeClassName: 'is-active',
  };

  constructor(props = {}) {
    // Prepare

    // 必要なDOMが存在しない場合、スクリプトを実行させない
    this.appElm = props.appElm ?? this.defaultProps.appElm;
    this.swiperElm = this.appElm.querySelector('[data-role="top-areamap-app-swiper"]');
    this.swiperNextNavElm = this.swiperElm.querySelector('[data-role="top-areamap-app-swiper-next"]');
    this.swiperPrevNavElm = this.swiperElm.querySelector('[data-role="top-areamap-app-swiper-prev"]');
    this.mapElm = this.appElm.querySelector('[data-role="top-areamap-app-map"]');
    this.areaElms = this.mapElm.querySelectorAll('area');
    this.baseImageElm = this.appElm.querySelector('[data-role="top-areamap-app-base-map-img"]');
    this.partialImageElms = this.appElm.querySelectorAll('[data-role="top-areamap-app-partial-img"]');
    const isElmsExist =
      this.appElm &&
      this.swiperElm &&
      this.swiperNextNavElm &&
      this.swiperPrevNavElm &&
      this.mapElm &&
      this.areaElms &&
      this.baseImageElm &&
      this.partialImageElms;
    if (!isElmsExist) throw new Error('[Top Area Map App : Error] 必要なDOM要素が足りません');

    // Setting

    this.app = this;
    this.activeClassName = props.activeClassName ?? this.defaultProps.activeClassName;
    this.bp = props.bp ?? this.defaultProps.bp;
    this.mapClickScrollTarget = props.mapClickScrollTarget ?? this.appElm;
    this.mapClickScrollTargetOffset = props.mapClickScrollTargetOffset ?? 0;

    this.app.state = {
      areaId: props.currentAreaId ?? this.defaultProps.areaId,
    };

    // Create sub instances

    this.carousel = new Carousel({
      app: this,
      elm: this.swiperElm,
      nextElm: this.swiperNextNavElm,
      prevElm: this.swiperPrevNavElm,
    });

    this.imagemapAreas = [];
    this.areaElms.forEach((elm) => {
      this.imagemapAreas.push(
        new ImagemapArea({
          app: this.app,
          elm,
          scrollLocationTarget: this.mapClickScrollTarget,
          scrollLocationTargetOffset: this.mapClickScrollTargetOffset,
        })
      );
    });

    this.partialImages = [];
    this.partialImageElms.forEach((elm) => {
      this.partialImages.push(new PartialImage({ app: this.app, elm }));
    });

    this.imagemapResizer = new ImagemapResizer({
      imageMapElm: this.mapElm,
      baseImageElm: this.baseImageElm,
    });

    // events

    window.addEventListener('resize', () => {
      this.state.isSpLayout = window.innerWidth < this.bp;
      this.imagemapResizer.render();
    });

    // initial

    this.init();
    this.imagemapResizer.init();
  }

  init() {
    // 1. アクティブなエリアが決まっていない場合は、最初のエリアをアクティブ扱いにする
    if (this.state.areaId === null) this.state.areaId = this.imagemapAreas[0].id;

    // 2. SPサイズのwindowかどうかを判定
    this.state.isSpLayout = window.innerWidth < this.bp;

    // 3. 全てのデザイン要素の状態アップデート
    this.render();
  }

  // 全てのサブインスタンスを更新
  render() {
    this.imagemapAreas.forEach((imagemapArea) => {
      imagemapArea.render();
    });

    this.partialImages.forEach((partialImage) => {
      partialImage.render();
    });

    this.carousel.render();
  }
}
