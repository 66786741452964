class PartialImage {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.id = this.elm.dataset.areaId;
  }

  render() {
    const isActiveImage = this.app.state.areaId === this.id;
    if (isActiveImage) {
      this.elm.style.opacity = 1;
      this.elm.classList.add(this.app.activeClassName);
    }
    if (!isActiveImage) {
      this.elm.style.opacity = 0;
      this.elm.classList.remove(this.app.activeClassName);
    }
  }
}

export default PartialImage;
