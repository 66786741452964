// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

import TopAreaMapApp from './components/TopAreaMapApp/TopAreaMapApp';

$(() => {
  if ($('#user_recommend_spot').length) {
    $.ajax({
      url: '/user_recommend_spot',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }
});

// MV swiper
if (document.querySelector("[data-role='top-mv-swiper']") !== null) {
  let topMvSwiper = new Swiper("[data-role='top-mv-swiper']", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    pagination: {
      el: "[data-role='top-mv-swiper-pagination']",
      clickable: true,
    },
  });
}

// 特集swiper
const topFeatureSwipers = document.querySelectorAll('.top-feature-swiper-container');
topFeatureSwipers.forEach((elm) => {
  const slides = elm.querySelectorAll('.top-feature-swiper-slide');
  const slideCount = slides.length;
  const navigation = elm.parentNode.querySelector('.top-feature-swiper-navigation');

  // スマホでの最小スライド数
  const minSlideCountMobile = 2;
  // タブレット以上での最小スライド数
  const minSlideCountTablet = 5;

  if (
    (window.innerWidth < 768 && slideCount >= minSlideCountMobile) ||
    (window.innerWidth >= 768 && slideCount >= minSlideCountTablet)
  ) {
    new Swiper(elm, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
      },
      navigation: {
        nextEl: navigation.querySelector('.top-feature-swiper-btn--type_next'),
        prevEl: navigation.querySelector('.top-feature-swiper-btn--type_prev'),
      },
    });
  } else {
    // Swiperが発動されていない場合、ナビゲーションを非表示にする
    navigation.style.display = 'none';
    // Swiperが発動していない場合、custom-swiper-containerにnon-activeクラスを追加
    elm.classList.add('non-active');
  }
});

// 動画swiper
if (document.querySelector("[data-role='top-movie-swiper']") !== null) {
  let topMovieSwiper = new Swiper("[data-role='top-movie-swiper']", {
    slidesPerView: 3,
    spaceBetween: 16,
    loop: true,
    speed: 500,
    navigation: {
      nextEl: "[data-role='top-movie-swiper-next']",
      prevEl: "[data-role='top-movie-swiper-prev']",
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
  });
}

// 季節のおすすめswiper
const topSeasonSwiperElms = document.querySelectorAll("[data-role='top-season-swiper']");
if (topSeasonSwiperElms.length) {
  topSeasonSwiperElms.forEach((elm) => {
    let topSeasonSwiper = new Swiper(elm, {
      slidesPerView: 1.4,
      spaceBetween: 16,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      centeredSlides: true,
      navigation: {
        nextEl: elm.querySelector("[data-role='top-season-swiper-next']"),
        prevEl: elm.querySelector("[data-role='top-season-swiper-prev']"),
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          spaceBetween: 24,
          centeredSlides: false,
        },
      },
    });
  });
}

// モデルコースswiper
if (document.querySelector("[data-role='top-course-swiper']") !== null) {
  let topFeatureSwiper = new Swiper("[data-role='top-course-swiper']", {
    slidesPerView: 1.2,
    spaceBetween: 12,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-course-swiper-next']",
      prevEl: "[data-role='top-course-swiper-prev']",
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 24,
        centeredSlides: false,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
        centeredSlides: false,
      },
      1012: {
        slidesPerView: 4,
        spaceBetween: 24,
        centeredSlides: false,
      },
    },
  });
}

// モデルコースswiper
if (document.querySelector("[data-role='top-more-swiper']") !== null) {
  let topFeatureSwiper = new Swiper("[data-role='top-more-swiper']", {
    slidesPerView: 1,
    spaceBetween: 12,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-more-swiper-next']",
      prevEl: "[data-role='top-more-swiper-prev']",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  });
}

// エリアマップ
const areaMapAppElm = document.querySelector('[data-role="top-areamap-app"]');
if (areaMapAppElm !== null) {
  // # 実行サンプル
  //
  // ## オプション一覧
  //
  // ※ 全て省略可能。デフォルト値は定義スクリプトを確認すること
  //
  // - appElm => 全体をラップするhtml要素
  // - areaId => 初期状態に選択状態にしたいエリアのID名
  // - bp => UIが1カラム縦積み(スマホレイアウト)になる分岐点
  // - activeClassName => 選択中の、areaタグ, エリア画像, swiperアイテムに自動で付加されるクラス名
  //
  // ## 全てのオプションを指定した例
  //
  // new TopAreaMapApp({
  //   appElm: document.querySelector('.my-areamap-app'),
  //   areaId: 'kenou',
  //   bp: 999,
  //   activeClassName: 'my-active-class',
  // });
  new TopAreaMapApp({
    appElm: areaMapAppElm,
    bp: 768,
    mapClickScrollTarget: document.querySelector('.top-ma-sp-slide-position'),
    mapClickScrollTargetOffset: -80,
  });
}

function LazyLoadSns(src) {
  const scriptTag = document.createElement('script');
  scriptTag.src = src;
  scriptTag.async = true;

  document.body.appendChild(scriptTag);
}

function snsLazyLoad() {
  const facebookEmbed = document.getElementsByClassName('share-btn-list__item--facebook');
  const twitterEmbed = document.getElementsByClassName('share-btn-list__item--twitter');
  const lineEmbed = document.getElementsByClassName('share-btn-list__item--line');
  try {
    if (facebookEmbed.length !== 0) LazyLoadSns('https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v17.0');
    if (twitterEmbed.length !== 0) LazyLoadSns('https://platform.twitter.com/widgets.js');
    if (lineEmbed.length !== 0) LazyLoadSns('https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js');
  } catch (error) {
    console.log(error);
  }
}
window.addEventListener('scroll', snsLazyLoad, { once: true });

// topページのmovieエリア
if (document.querySelector('#topMovieModal') !== null) {
  // サムネイルクリック時のモーダル内動画src指定
  Array.prototype.slice.call(document.querySelectorAll('.top-youtube-thumbnail'), 0).forEach((node) => {
    node.addEventListener('click', () => {
      const { url } = node.dataset;
      document.getElementById('topYoutubeMovie').src = url;
    });
  });
  // モーダル閉じた時のモーダル内動画src空に
  $('#topMovieModal').on('hide.bs.modal', () => {
    document.querySelector('#topYoutubeMovie').src = '';
  });
}
