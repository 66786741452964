// windowのリサイズ時に適切なイメージマップの座標を計算するスクリプト

class ImagemapResizer {
  constructor({ imageMapElm, baseImageElm }) {
    this.map = imageMapElm;
    this.baseImg = baseImageElm;
    this.originW = Number(this.map.dataset.originW);
    this.originH = Number(this.map.dataset.originH);
    this.areas = this.map.querySelectorAll('area');

    this.areaCoordsArrs = [];
    this.areas.forEach((area) => {
      const coords = area.coords
        .replace(/ /g, '')
        .split(',')
        .map((n) => Number(n));
      this.areaCoordsArrs.push(coords);
    });

    this.init();
  }

  init() {
    // 初回レンダリング
    this.baseImg.onload = () => {
      this.render();
    };
  }

  render() {
    const w = this.baseImg.getBoundingClientRect().width;
    const h = this.baseImg.getBoundingClientRect().height;
    const ratioW = w / this.originW;
    const ratioH = h / this.originH;

    this.areas.forEach((area, index) => {
      const areaNode = area;
      areaNode.coords = this.areaCoordsArrs[index]
        .map((n, i) => {
          if (i % 2 === 0) return Math.floor(n * ratioW);
          return Math.floor(n * ratioH);
        })
        .toString();
    });
  }
}

export default ImagemapResizer;
